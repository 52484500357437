import { SVGProps } from "react";

export const SvgIconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none" viewBox="0 0 8 8" {...props}>
    <path
      fill="#fff"
      d="M2.664 7.203a.534.534 0 01-.377-.157L.153 4.912a.534.534 0 01.754-.755l1.737 1.737L7.07.976a.534.534 0 01.793.714L3.061 7.026a.535.535 0 01-.383.177h-.014z"
    ></path>
  </svg>
);
