import { StoreStaffApi } from "@unit/apis";
import {
  signInWithEmailAndPassword,
  signOut,
  EmailAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL } from "@/global-state/firebase-settings";
import { firebaseGetAuth } from "@/global-state/firebase-settings";
import { authUserAtom, idTokenAtom, loadingAtom, selectStoreAtom } from "@/global-state/jotai-atom";
import { homePath, loginPath } from "@/global-state/store-app-path";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "518bb79dc9bb888f72f686ce77f5feb8";
const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY = "381dd579d84447c7701272c76b109f40";

export const useAuthentication = () => {
  const router = useRouter();
  const { setAppSnackbar } = useAppSnackbar();

  const [, setLoading] = useAtom(loadingAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const [selectStore, setSelectStoreAtom] = useAtom(selectStoreAtom);

  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(undefined);
    setLoading(false);
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, "");
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, "");
    await router.push(loginPath);
  }, []);

  const syncIdToken = useCallback(async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const expiresAtStr = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY);
    if (accessToken && expiresAtStr) {
      const now = new Date().getTime();
      const expiresAt = parseInt(expiresAtStr);
      if (now < expiresAt) {
        setIdToken(accessToken);
        return accessToken;
      }
    }

    return new Promise<string | null>((resolve) => {
      firebaseGetAuth.onAuthStateChanged(async (user) => {
        const accessToken = (await user?.getIdToken(true)) || undefined;
        if (!accessToken) {
          await resetAuth();
          resolve(null);
          return;
        }

        // 有効期限は取得したタイミングから1時間以内だが、アプリ上では余裕を持って、30分以内とする
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, `${new Date().getTime() + 30 * 60 * 1000}`);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);

        await setIdToken(accessToken);
        resolve(accessToken);
      });
    });
  }, []);

  const syncAuthUser = useCallback(async () => {
    setLoading(true);
    try {
      const checkApi = new StoreStaffApi(API_URL, idToken);
      const member = await checkApi.getStaffProfile();
      await setAuthUser(member.object);
    } catch (e: any) {
      console.error(e?.response?.data?.message || e?.response?.data?.devMessage);
      await resetAuth();
    } finally {
      setLoading(false);
    }
  }, [idToken]);

  const authReFetchMember = useCallback(async () => {
    setLoading(true);
    firebaseGetAuth.onAuthStateChanged(async (user) => {
      const accessToken = (await user?.getIdToken(true)) || undefined;
      if (!accessToken) return resetAuth();
      await setIdToken(accessToken);
      const checkApi = new StoreStaffApi(accessToken);
      const member = await checkApi.getStaffProfile();
      if (!member) return resetAuth();
      await setAuthUser(member.object);
      await setSelectStoreAtom(member.object.stores[0]);
    });
    setLoading(false);
  }, []);

  // Email&Passwordでのログイン
  const authLoginWithEmail = useCallback(async (email: string, password: string) => {
    const accessToken = await signInWithEmailAndPassword(firebaseGetAuth, email, password)
      .then(async (credential) => (await credential.user?.getIdToken(true)) || undefined)
      .catch((error) => {
        let errorMessage = "";
        switch (error.code) {
          case "auth/user-not-found":
            errorMessage = "ストア管理者で登録がありませんでした";
            break;
          case "auth/wrong-password":
            errorMessage = "パスワードが一致しませんでした。";
            break;
          default:
            errorMessage = error?.response?.data?.detail || error?.message || error;
        }
        setAppSnackbar(errorMessage, { error: true });
        return undefined;
      });
    if (!accessToken) {
      return await resetAuth();
    }
    try {
      await setIdToken(accessToken);

      const checkApi = new StoreStaffApi(API_URL, accessToken);
      const member = await checkApi.getStaffProfile();
      const initStore = member.object.stores[0];
      if (!initStore) {
        throw new Error("どのストアにも所属していないユーザーなのでログインできません");
      }
      await setAuthUser(member.object);
      await setSelectStoreAtom(initStore);
      await router.push(homePath);
      setAppSnackbar(`${member.object.name}でログインしました`, { success: true });
    } catch (e: any) {
      setAppSnackbar(e.message || "ストア管理者ではありません", { error: true });
      await resetAuth();
    }
  }, []);

  // ログアウト
  const authLogout = useCallback(async () => {
    setLoading(true);
    signOut(firebaseGetAuth)
      .then(async () => {
        setAppSnackbar("ログアウトしました。", { info: true });
        await resetAuth();
      })
      .catch((error) => {
        setAppSnackbar(error?.message || "error", { error: true });
      })
      .finally(() => setLoading(false));
  }, []);

  const authResetPassword = useCallback(async (prevPassword: string, newPassword: string) => {
    setLoading(true);
    const user = firebaseGetAuth.currentUser;
    const providerData = user?.providerData.length ? user?.providerData[0].providerId : "";
    if (!user || !user.email || !providerData) return await resetAuth();
    if (providerData === "google.com") {
      return setAppSnackbar("Googleでログインしているためパスワードの変更はできません。", { success: true });
    } else if (providerData === "facebook.com") {
      return setAppSnackbar("Facebookでログインしているためパスワードの変更はできません。", { success: true });
    }
    const credential = await EmailAuthProvider.credential(user.email, prevPassword);
    if (!credential) return resetAuth();
    await reauthenticateWithCredential(user, credential)
      .then(async (cred) => {
        await updatePassword(cred.user, newPassword).then(() => {
          setAppSnackbar("パスワードの更新に成功しました。", { success: true });
        });
      })
      .catch((error) => {
        console.log(error);
        setAppSnackbar("パスワードを更新できませんでした", { error: true });
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    resetAuth,
    syncIdToken,
    syncAuthUser,
    authReFetchMember,
    authLoginWithEmail,
    authLogout,
    authResetPassword,
  };
};
