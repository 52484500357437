import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreInfluencerApi extends BaseApi {
  async getInfluencerList(
    parameters: APIPaths["/store-app/influencer/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/influencer/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/influencer/list", {
        params: parameters,
      })
    ).data;
  }
  async getInfluencerById(
    id: APIPaths["/store-app/influencer/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/influencer/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/influencer/${id}`)).data;
  }
  async getFavoriteInfluencerList(
    parameters: APIPaths["/store-app/influencer/favorite/list"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/store-app/influencer/favorite/list"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.get("/store-app/influencer/favorite/list", {
        params: parameters,
      })
    ).data;
  }
  async putFavoriteInfluencerById(
    id: APIPaths["/store-app/influencer/{id}/favorite"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer/{id}/favorite"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/influencer/{id}/favorite"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/influencer/${id}/favorite`, requestBody)).data;
  }
  async deleteFavoriteInfluencerById(
    id: APIPaths["/store-app/influencer/{id}/favorite"]["delete"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer/{id}/favorite"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/influencer/{id}/favorite"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.delete(`/store-app/influencer/${id}/favorite`, {
        data: requestBody,
      })
    ).data;
  }
  async putInfluencerMemoById(
    id: APIPaths["/store-app/influencer/{id}/memo"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/influencer/{id}/memo"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/influencer/{id}/memo"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/influencer/${id}/memo`, requestBody)).data;
  }
}
