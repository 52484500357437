import { APICompsSchema } from "@unit/apis";
import { useAtom } from "jotai";
import localforage from "localforage";
import React, { useEffect } from "react";
import { useQueries } from "react-query";

import InitialScreenContainer from "@/components/common/InitialScreenContainer";
import { BackdropProgress } from "@/components/provider/BackdropProgress";
import { useAreaApi } from "@/custom-hooks/apis/use-area-api";
import { useGenreApi } from "@/custom-hooks/apis/use-genre-api";
import { useStaffApi } from "@/custom-hooks/apis/use-staff-api";
import { useTicketApi } from "@/custom-hooks/apis/use-ticket-api";
import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { useAuthentication } from "@/custom-hooks/use-authentication";
import { authUserAtom, currentStoresTicketsAtom, idTokenAtom, selectStoreAtom } from "@/global-state/jotai-atom";
import { QueryKeys } from "@/global-state/react-query-keys";

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = (props) => {
  const { setAppSnackbar } = useAppSnackbar();
  const { syncIdToken, resetAuth } = useAuthentication();
  const [authUser, setAuthUser] = useAtom(authUserAtom);
  const [idToken] = useAtom(idTokenAtom);

  const [selectStore, setSelectStoreAtom] = useAtom(selectStoreAtom);
  const [, setCurrentStoreTickets] = useAtom(currentStoresTicketsAtom);

  const { getAreaList } = useAreaApi();
  const { getGenreList } = useGenreApi();
  const { getStaffProfile } = useStaffApi();
  const { getTicketList } = useTicketApi();

  const handleWindowFocusChange = async () => {
    if (!document.hidden) {
      await syncIdToken();
    }
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", handleWindowFocusChange);
    return () => {
      document.removeEventListener("visibilitychange", handleWindowFocusChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = await syncIdToken();
      if (!accessToken) {
        return await resetAuth();
      }
    })();
  }, [idToken]);

  useEffect(() => {
    if (!authUser?.id) {
      return;
    }
    const resetInterval = setInterval(() => syncIdToken(), 10 * 60 * 1000); // 10分間
    return () => clearInterval(resetInterval);
  }, [authUser?.id]);

  // GlobalStateとして扱うデータをAPIから取得
  const initApiResults = useQueries([
    {
      queryKey: QueryKeys.getAreaList,
      queryFn: () => getAreaList(),
    },
    {
      queryKey: QueryKeys.getGenreList,
      queryFn: () => getGenreList(),
    },
    {
      queryKey: [QueryKeys.getStaffProfile],
      queryFn: () => getStaffProfile(),
      onSuccess: async (res: any) => {
        const staff = res.object as APICompsSchema["StaffObject"];
        await setAuthUser(staff);
        const storeId = await localforage.getItem("selected_store_id");
        const targetStore = staff.stores.find((store) => store.id === storeId) || staff.stores[0];
        if (!targetStore) {
          setAppSnackbar("どのストアにも所属していないユーザーです", { error: true });
          await resetAuth();
        }
        setSelectStoreAtom(targetStore);
      },
      onError: async (e: any) => {
        const errorObj = e?.response?.data;
        console.error(errorObj?.message || errorObj?.devMessage);
        await resetAuth();
      },
      enabled: !!idToken,
    },
    {
      queryKey: [QueryKeys.getTicketList, selectStore?.id],
      queryFn: () =>
        getTicketList(
          {
            storeId: selectStore?.id,
            status: "open,closed",
            type: selectStore?.type || undefined,
            sortBy: "entryStartDate-desc",
            limit: 200,
          },
          true,
        ),
      onSuccess: (res: any) => setCurrentStoreTickets(res.objects),
      enabled: !!idToken && !!selectStore?.id,
      refetchInterval: 180000, // 3分毎
      refetchIntervalInBackground: true,
    },
  ]);

  const loading = initApiResults[0].isLoading || initApiResults[1].isLoading || initApiResults[2].isLoading;

  if (loading && !authUser?.id) {
    return (
      <BackdropProgress open>
        <InitialScreenContainer />
      </BackdropProgress>
    );
  }

  return <>{props.children}</>;
};

export default AuthProvider;
