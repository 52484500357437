export const QueryKeys = {
  getAuthUser: "getAuthUser",
  getGenreList: "getGenreList",
  getAreaList: "getAreaList",
  getAdminMemberList: "getAdminMemberList",
  getAdminMemberById: "getAdminMemberById",
  getOrganizationList: "getOrganizationList",
  getBrandList: "getBrandList",
  getBrandById: "getBrandById",
  getStoreList: "getStoreList",
  getStoreById: "getStoreById",
  getStaffList: "getStaffList",
  getStaffProfile: "getStaffProfile",
  getInfluencerList: "getInfluencerList",
  getInfluencerById: "getInfluencerById",
  getFavoriteInfluencerList: "getFavoriteInfluencerList",
  getPrTicketList: "getPrTicketList",
  getTicketList: "getTicketList",
  getStoreTicketList: "getStoreTicketList",
  getTicketById: "getTicketById",
  getInfluencerTicketList: "getInfluencerTicketList",
  getInfluencerStatusLogList: "getInfluencerStatusLogList",
  getInfluencerTicketPostList: "getInfluencerTicketPostList",

  fecthRoomMessage: "fecthRoomMessage",
  fecthAllRoom: "fecthAllRoom",
  fetchRoomById: "fetchRoomById",
  fetchRoomByTicketId: "fetchRoomByTicketId",

  getStoreClicks: "getStoreClicks",
  getStoreTicketActions: "getStoreTicketActions",
  getStoreFavorites: "getStoreFavorites",
  getStoreRatings: "getStoreRatings",
  getStoreInfluencerTicketsSummary: "getStoreInfluencerTicketsSummary",
  getStorePostsSummary: "getStorePostsSummary",
  getInsightsByStoreId: "getInsightsByStoreId",
  getInfluencerTicketPostsCount: "getInfluencerTicketPostsCount",
  getInfluencerTicketsCount: "getInfluencerTicketsCount",
  getInsightsByTicketIds: "getInsightsByTicketIds",
} as const;
