import { APIPaths, StoreTicketApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useCallback } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL } from "@/global-state/firebase-settings";
import { idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useTicketApi = () => {
  const { setAppSnackbar } = useAppSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const ticketApi = useCallback(() => new StoreTicketApi(API_URL, idToken), [idToken]);

  const getTicketList = useCallback(
    async (
      parameters: APIPaths["/store-app/ticket/list"]["get"]["parameters"]["query"],
      ignoreLoading: boolean = false,
    ) => {
      if (!ignoreLoading) await setLoading(true);
      try {
        return await ticketApi().getTicketList(parameters);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const registerTicketNew = useCallback(
    async (requestBody: APIPaths["/store-app/ticket/new"]["post"]["requestBody"]["content"]["application/json"]) => {
      await setLoading(true);
      try {
        return await ticketApi().registerTicketNew(requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const getTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}"]["get"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().getTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const patchTicketById = useCallback(
    async (
      id: APIPaths["/store-app/ticket/{id}"]["patch"]["parameters"]["path"]["id"],
      requestBody: APIPaths["/store-app/ticket/{id}"]["patch"]["requestBody"]["content"]["application/json"],
    ) => {
      await setLoading(true);
      try {
        return await ticketApi().patchTicketById(id, requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const deleteTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}"]["delete"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().deleteTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const putCloseTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}/close"]["put"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().putCloseTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const putRequestTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}/request"]["put"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().putRequestTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const putCancelRequestTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}/request-cancel"]["put"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().putCancelRequestTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const putOpenTicketById = useCallback(
    async (id: APIPaths["/store-app/ticket/{id}/open"]["put"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await ticketApi().putOpenTicketById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  const putOfferTicketToInfluencerById = useCallback(
    async (
      id: APIPaths["/store-app/ticket/{id}/offer"]["put"]["parameters"]["path"]["id"],
      requestBody: APIPaths["/store-app/ticket/{id}/offer"]["put"]["requestBody"]["content"]["application/json"],
    ) => {
      await setLoading(true);
      try {
        return await ticketApi().putOfferTicketToInfluencerById(id, requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [ticketApi],
  );

  return {
    getTicketList,
    registerTicketNew,
    getTicketById,
    patchTicketById,
    deleteTicketById,
    putRequestTicketById,
    putCloseTicketById,
    putCancelRequestTicketById,
    putOpenTicketById,
    putOfferTicketToInfluencerById,
  };
};
