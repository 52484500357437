import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreReportApi extends BaseApi {
  async getStoreClicks(
    parameters: APIPaths["/store-app/report/store-clicks"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/report/store-clicks"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/report/store-clicks", {
        params: parameters,
      })
    ).data;
  }
  async getStoreTicketActions(
    parameters: APIPaths["/store-app/report/store-ticket-actions"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/store-app/report/store-ticket-actions"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.get("/store-app/report/store-ticket-actions", {
        params: parameters,
      })
    ).data;
  }
  async getStoreFavorites(
    parameters: APIPaths["/store-app/report/store-favorites"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/report/store-favorites"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/report/store-favorites", {
        params: parameters,
      })
    ).data;
  }
  async getStoreRatings(
    parameters: APIPaths["/store-app/report/store-ratings"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/report/store-ratings"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/report/store-ratings", {
        params: parameters,
      })
    ).data;
  }
}
