import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreStaffApi extends BaseApi {
  async getStaffProfile(): Promise<
    APIPaths["/store-app/staff/me"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/store-app/staff/me")).data;
  }
  async patchStaffProfile(
    requestBody: APIPaths["/store-app/staff/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/staff/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch("/store-app/staff/me", requestBody)).data;
  }
  async staffActivate(): Promise<
    APIPaths["/store-app/staff/me/activate"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put("/store-app/staff/me/activate")).data;
  }
  async getStaffList(
    parameters: APIPaths["/store-app/staff/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/staff/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/staff/list", {
        params: parameters,
      })
    ).data;
  }
  async registerStaffNew(
    requestBody: APIPaths["/store-app/staff/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/staff/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/store-app/staff/new", requestBody)).data;
  }
  async getStaffById(
    id: APIPaths["/store-app/staff/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/staff/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/staff/${id}`)).data;
  }
  async patchStaffProfileById(
    id: APIPaths["/store-app/staff/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/staff/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/staff/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/store-app/staff/${id}`, requestBody)).data;
  }
  async deleteStaffById(
    id: APIPaths["/store-app/staff/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/staff/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/store-app/staff/${id}`)).data;
  }
  async postChangePasswordStaff(
    requestBody: APIPaths["/store-app/staff/me/change-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/store-app/staff/me/change-password"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/store-app/staff/me/change-password`, requestBody)).data;
  }
}
