import { SVGProps } from "react";

export const SVGIconSchedule = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#fff"
      d="M5 22c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 013 20V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 015 4h1V2h2v2h8V2h2v2h1c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v6h-2v-2H5v10h7v2H5zm17.125-5L20 14.875l.725-.725a.948.948 0 01.7-.275c.283 0 .517.092.7.275l.725.725a.948.948 0 01.275.7.948.948 0 01-.275.7l-.725.725zM14 23v-2.125l5.3-5.3 2.125 2.125-5.3 5.3H14z"
    ></path>
  </svg>
);
