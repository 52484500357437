import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreTicketApi extends BaseApi {
  async getTicketList(
    parameters: APIPaths["/store-app/ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/store-app/ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/store-app/ticket/list", {
        params: parameters,
      })
    ).data;
  }
  async registerTicketNew(
    requestBody: APIPaths["/store-app/ticket/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/ticket/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/store-app/ticket/new", requestBody)).data;
  }
  async getTicketById(
    id: APIPaths["/store-app/ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/store-app/ticket/${id}`)).data;
  }
  async patchTicketById(
    id: APIPaths["/store-app/ticket/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/ticket/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/ticket/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/store-app/ticket/${id}`, requestBody)).data;
  }
  async deleteTicketById(
    id: APIPaths["/store-app/ticket/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/ticket/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/store-app/ticket/${id}`)).data;
  }

  async putRequestTicketById(
    id: APIPaths["/store-app/ticket/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/ticket/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/ticket/${id}/request`)).data;
  }
  async putCancelRequestTicketById(
    id: APIPaths["/store-app/ticket/{id}/request-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/store-app/ticket/{id}/request-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/store-app/ticket/${id}/request-cancel`)).data;
  }
  async putOpenTicketById(
    id: APIPaths["/store-app/ticket/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/ticket/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/ticket/${id}/open`)).data;
  }
  async putCloseTicketById(
    id: APIPaths["/store-app/ticket/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/store-app/ticket/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/ticket/${id}/close`)).data;
  }
  async putOfferTicketToInfluencerById(
    id: APIPaths["/store-app/ticket/{id}/offer"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/store-app/ticket/{id}/offer"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/store-app/ticket/{id}/offer"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/store-app/ticket/${id}/offer`, requestBody)).data;
  }
}
