import { SVGProps } from "react";

export const SVGIconRating = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
    <rect width="40" height="40" fill={props.fill ?? "#3AB7FF"} rx="20"></rect>
    <path
      fill="#fff"
      d="M14 22h2.45l5.9-5.85c.1-.1.15-.225.15-.375s-.05-.275-.15-.375l-1.75-1.75a.509.509 0 00-.375-.15c-.15 0-.275.05-.375.15L14 19.55V22zm4.5 0H26v-2h-5.5l-2 2zM10 30V12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0112 10h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0128 26H14l-4 4z"
    ></path>
  </svg>
);
