import { APIPaths, StoreStaffApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL } from "@/global-state/firebase-settings";
import { idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useStaffApi = () => {
  const { setAppSnackbar } = useAppSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const staffApi = useMemo(() => new StoreStaffApi(API_URL, idToken), [idToken]);

  const getStaffList = useCallback(
    async (parameters: APIPaths["/store-app/staff/list"]["get"]["parameters"]["query"]) => {
      await setLoading(true);
      try {
        return await staffApi.getStaffList(parameters);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const getStaffProfile = useCallback(async () => {
    await setLoading(true);
    try {
      return await staffApi.getStaffProfile();
    } catch (e: any) {
      setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
        error: true,
      });
      throw e;
    } finally {
      await setLoading(false);
    }
  }, [staffApi]);

  const patchStaffProfile = useCallback(
    async (requestBody: APIPaths["/store-app/staff/me"]["patch"]["requestBody"]["content"]["application/json"]) => {
      await setLoading(true);
      try {
        return await staffApi.patchStaffProfile(requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const staffActivate = useCallback(async () => {
    await setLoading(true);
    try {
      return await staffApi.staffActivate();
    } catch (e: any) {
      setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
        error: true,
      });
      throw e;
    } finally {
      await setLoading(false);
    }
  }, [staffApi]);

  const registerStaffNew = useCallback(
    async (requestBody: APIPaths["/store-app/staff/new"]["post"]["requestBody"]["content"]["application/json"]) => {
      await setLoading(true);
      try {
        return await staffApi.registerStaffNew(requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const getStaffById = useCallback(
    async (id: APIPaths["/store-app/staff/{id}"]["get"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await staffApi.getStaffById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const patchStaffProfileById = useCallback(
    async (
      id: APIPaths["/store-app/staff/{id}"]["patch"]["parameters"]["path"]["id"],
      requestBody: APIPaths["/store-app/staff/{id}"]["patch"]["requestBody"]["content"]["application/json"],
    ) => {
      await setLoading(true);
      try {
        return await staffApi.patchStaffProfileById(id, requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const deleteStaffById = useCallback(
    async (id: APIPaths["/store-app/staff/{id}"]["delete"]["parameters"]["path"]["id"]) => {
      await setLoading(true);
      try {
        return await staffApi.deleteStaffById(id);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  const postChangePasswordStaff = useCallback(
    async (
      requestBody: APIPaths["/store-app/staff/me/change-password"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      await setLoading(true);
      try {
        return await staffApi.postChangePasswordStaff(requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
        throw e;
      } finally {
        await setLoading(false);
      }
    },
    [staffApi],
  );

  return {
    getStaffList,
    registerStaffNew,
    getStaffById,
    patchStaffProfileById,
    deleteStaffById,
    staffActivate,
    getStaffProfile,
    patchStaffProfile,
    postChangePasswordStaff,
  };
};
