import { GenreApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL } from "@/global-state/firebase-settings";
import { genresAtom, idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useGenreApi = () => {
  const { setAppSnackbar } = useAppSnackbar();
  const [, setGenres] = useAtom(genresAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const AdminGenreApi = useMemo(() => new GenreApi(API_URL, idToken), [idToken]);

  const getGenreList = useCallback(async () => {
    await setLoading(true);
    try {
      const result = await AdminGenreApi.getGenreList();
      return setGenres(result.objects);
    } catch (e: any) {
      setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "", {
        error: true,
      });
      throw e;
    } finally {
      await setLoading(false);
    }
  }, [AdminGenreApi]);

  return {
    getGenreList,
  };
};
